import React, { useContext, useEffect, useState } from 'react';
import { ThemeContext } from 'styled-components';
import { useIntl } from 'react-intl';
import '@formatjs/intl-displaynames/polyfill';
import '@formatjs/intl-displaynames/locale-data/en';
import { isEmpty } from 'lodash';
import { DefaultTemplate } from '@luigiclaudio/ga-baseline-ui/template';
import { Container, Row, Col } from '@luigiclaudio/ga-baseline-ui/layout';
import {
    useHasMounted,
    Loader,
    HelmetDetails,
    TransitionItem,
} from '@luigiclaudio/ga-baseline-ui/helpers';
import { AlertFeedback } from '@luigiclaudio/ga-baseline-ui/alert';
import productTypeList from '../data/productTypeList.json';
import BrandContext from '../components/context/BrandContext';
import brandDetailsOnStageInitialState from '../components/context/brandDetailsOnStageInitialState';
import BrandDetails from '../components/app/BrandDetails';
import brandUtils from '../utils/brandUtils';
import countryUtils from '../utils/countryUtils';
import pageContextPropTypes from '../prop-types/pageContextPropTypes';
import useAppUser from '../services/useAppUser';
import appConfig from '../data/appConfig.json';
import ProductCatalogAsideFilters from '../components/app/ProductCatalogAsideFilters';

const BrandCountryPageTemplate = ({ pageContext }) => {
    const intl = useIntl();
    const { multiCountry } = appConfig;
    const {
        brandProductsOnStage,
        setBrandOnStage,
        productTypeOnStage,
        searchFilterTypes,
        setBrandDetailsOnStage,
        setBrandProductsOnStage,
        brandDetailsOnStage,
        setProductFilterList,
        setProductTypeListOnSelection,
        setProductTypeOnStage,
        catalogFiltersOnStage,
    } = useContext(BrandContext);
    const theme = useContext(ThemeContext);
    const hasMounted = useHasMounted();

    const { brand, country } = pageContext;

    const [brandApi, setBrandApi] = useState();

    useEffect(() => {
        setBrandDetailsOnStage({
            ...brandDetailsOnStageInitialState,
        });
        setBrandProductsOnStage(undefined);
        setProductFilterList(undefined);
        setProductTypeOnStage(undefined);
        return () => {
            setProductFilterList(undefined);
            setProductTypeOnStage(undefined);
        };
    }, []);

    useEffect(() => {
        if (brand && country) {
            const getBrand = async () => {
                const response = await brandUtils.brandApi(brandUtils.getBrandSlug(brand), country);
                setBrandApi(response);
            };
            getBrand();
        }
    }, [brand, country]);

    const { isLoadingSubscription, isErrorSubscription, userActiveGroups, username } = useAppUser();

    const [productsByRetailerByUser, setProductByRetailerByUser] = useState([]);

    useEffect(() => {
        if (brandApi) {
            setProductByRetailerByUser(
                brandUtils.productsByCountryByUserPreferences({
                    productsByRetailer: brandApi.productsByRetailer,
                    activeGroups: userActiveGroups || [],
                }),
            );
        }
    }, [brandApi, userActiveGroups]);

    useEffect(() => {
        setBrandOnStage(brand);
    }, [brand]);

    useEffect(() => {
        const availableProducts = [];
        const availabelProductTypes = [];

        availableProducts.push(productsByRetailerByUser);

        availableProducts.forEach((products) => {
            const filteredAvailableProducts = brandUtils.productByProductTypeOnStage(
                products,
                productTypeOnStage,
            );

            filteredAvailableProducts.forEach((product) =>
                availabelProductTypes.push(...product.intlKeywords),
            );
        });

        const count = {};
        const countArray = [];

        availabelProductTypes.forEach((item) => {
            count[item] = (count[item] || 0) + 1;
        });

        Object.entries(count).forEach(([countKey, countValue]) => {
            countArray.push({ keyword: countKey, amount: countValue });
        });

        const setPriorityByAZ = countArray.sort((a, b) => a.keyword.localeCompare(b.keyword, 'en'));
        const priorityByAZKeywords = setPriorityByAZ.map((item) => item.keyword);
        const narrowProductTypesAZ = priorityByAZKeywords.filter((item) =>
            productTypeList.includes(item.toLowerCase()),
        );

        setProductTypeListOnSelection(
            searchFilterTypes
                ? narrowProductTypesAZ.filter((item) => item.includes(searchFilterTypes))
                : narrowProductTypesAZ,
        );
    }, [searchFilterTypes, productTypeOnStage, productsByRetailerByUser, brandDetailsOnStage]);

    useEffect(() => {
        setBrandDetailsOnStage({
            brand,
            country: countryUtils.displayCountryName(intl, country),
            products: brandUtils.productByProductTypeOnStage(
                productsByRetailerByUser,
                productTypeOnStage,
            ),
        });
        setBrandProductsOnStage(productsByRetailerByUser);
    }, [productsByRetailerByUser, productTypeOnStage]);

    const renderBgColor = hasMounted ? theme.site.stage.backgroundColor : null;

    if (
        hasMounted &&
        (isLoadingSubscription || !brandApi) &&
        !productTypeOnStage &&
        !catalogFiltersOnStage
    ) {
        return <Loader isFullScreen fullScreenBackgroundColor={theme.site.stage.backgroundColor} />;
    }

    if (hasMounted && isEmpty(brandProductsOnStage)) {
        return <Loader isFullScreen fullScreenBackgroundColor={theme.site.stage.backgroundColor} />;
    }

    if (username && isErrorSubscription) {
        return (
            <AlertFeedback
                inProp
                alertType="danger"
                alertMessage={intl.formatMessage({ id: 'user.isErrorSubscription' })}
            />
        );
    }

    return (
        <>
            <HelmetDetails
                title={
                    multiCountry
                        ? intl.formatMessage(
                              { id: 'brand.multiCountry.title' },
                              {
                                  brand: brand.toUpperCase(),
                                  country: countryUtils.displayCountryName(intl, country),
                              },
                          )
                        : intl.formatMessage(
                              { id: 'brand.country.title' },
                              {
                                  brand: brand.toUpperCase(),
                              },
                          )
                }
            />
            <DefaultTemplate
                bgStart={renderBgColor}
                bgEnd={renderBgColor}
                isFullWidth
                content={
                    <Container isFullHeight>
                        <Row isFullHeight>
                            <Col xxs={30}>
                                {hasMounted && (
                                    <>
                                        <TransitionItem
                                            inProp
                                            appear
                                            mountOnEnter
                                            unmountOnExit
                                            isFullHeight
                                        >
                                            <ProductCatalogAsideFilters />
                                        </TransitionItem>

                                        <BrandDetails />
                                    </>
                                )}
                            </Col>
                        </Row>
                    </Container>
                }
            />
        </>
    );
};

BrandCountryPageTemplate.propTypes = {
    pageContext: pageContextPropTypes.brandCountryPageContext.isRequired,
};

export default BrandCountryPageTemplate;
