import PropTypes from 'prop-types';
import productsByRetailerPropTypes from './productsByRetailerPropTypes';
import productPropTypes from './productPropTypes';

const brandOverviewPageContext = PropTypes.shape({
    brand: PropTypes.string,
    id: PropTypes.string,
    retailers: PropTypes.arrayOf(
        PropTypes.shape({
            productsByCountry: PropTypes.arrayOf(
                PropTypes.shape({
                    country: PropTypes.string,
                    products: PropTypes.arrayOf(
                        PropTypes.shape({
                            group: productPropTypes.productGroup,
                            totalCount: PropTypes.number,
                        }),
                    ),
                    totalCount: PropTypes.number,
                }),
            ),
            retailer: PropTypes.string,
        }),
    ),
});

const brandCountryPageContext = PropTypes.shape({
    brand: PropTypes.string,
    country: PropTypes.string,
    currency: PropTypes.string,
    id: PropTypes.string,
    productsByRetailer: productsByRetailerPropTypes.productsByRetailerList,
});

export default { brandOverviewPageContext, brandCountryPageContext };
