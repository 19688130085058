import PropTypes from 'prop-types';
import productPropTypes from './productPropTypes';

const productsByRetailer = PropTypes.shape({
    products: PropTypes.arrayOf(
        PropTypes.shape({
            group: productPropTypes.productGroup,
            products: productPropTypes.products,
            totalCount: PropTypes.number,
        }),
    ),
    retailer: PropTypes.string,
    totalCount: PropTypes.number,
});

const productsByRetailerList = PropTypes.arrayOf(productsByRetailer);

export default { productsByRetailerList, productsByRetailer };
